(function ($) {
  Drupal.behaviors.selectLanguageV1 = {
    attach: function (context, settings) {
      $('.language-select__languages a').bind('click', function (event) {
        event.preventDefault();
        prefix = $(this).attr('data-mp-lang');
        if (prefix != '') {
          url = window.location.href;
          if (url.indexOf(prefix) < 0) {
            url = url.replace(window.location.host, window.location.host + '/' + prefix);
            window.location.href = url;
          } else {
            window.location.href = url;
          }
        } else {
          url = window.location.href;
          new_lang = $('.language-select__language02 a').attr('data-mp-lang');
          url = url.replace('/' + new_lang, '');
          window.location.href = url;
        }
      });
    }
  };
})(jQuery);
